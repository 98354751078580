import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.default};
  height: 100vh;
  overflow-x: hidden;

  /* mobile viewport bug fix */
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

export default PageWrapper;
