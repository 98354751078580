import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { mobileMenuBreakpoint } from '../breakpoints';

export const Link = styled(NavLink)`
  font-family: ${({ theme }) => theme.fonts.default};
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.1rem;
  color: ${({ theme }) => theme.colors.light};  

  &:active,
  &:focus {
    text-decoration: underline;
  }

  + a::before {
    content: "•";
    display: inline-block;
    width: 2rem;
    height: 1rem;
    text-align: center;
  }
`;

export const Container = styled.nav`
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 100%;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin: 0;

  @media (max-width: ${mobileMenuBreakpoint}) {
    position: fixed;
    flex-flow: column nowrap;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.secondary};
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 3.125rem;
    right: 0;
    height: calc(100vh - 3.125rem);
    width: 100%;
    padding-top: 1rem;
    transition: transform 0.3s ease-out;
    z-index: 10;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  @media (max-width: ${mobileMenuBreakpoint}) {
    flex-direction: column;
    .selectbox-header {
      padding: 0 0.625rem;
    }
  }
`;

export const Item = styled.li`
  margin: 0.5rem 0.8rem;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  height: 100%;

  a {
    display: block;
    height: 100%;
    padding: 0.5rem 0.625rem 0;
    font-size: 0.9rem;
    line-height: 0.64rem;
    font-weight: normal;
    border-bottom: 3px solid ${({ theme }) => theme.colors.secondary};
  }
  a:hover {
    text-decoration: underline;
  }

  @media (min-width: ${mobileMenuBreakpoint}) {
    a:hover, a:active, a:focus, a.active {
      text-decoration: none;
      border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: ${mobileMenuBreakpoint}) {
    margin: 0 0 0.8rem 0;
    padding: 0;
    height: initial;
  }
`;

export const LanguageSwitcherWrapper = styled.div`
  width: 7.375rem;
  font-size: 1.1875rem;
  .selectbox-header {
    display: initial;
    font-size: 1.1875rem;
    height: 3.125rem;
    line-height: 3.125rem;
  }
  @media (max-width: ${mobileMenuBreakpoint}) {
    width: 6.9rem;
    .selectbox-header:hover {
      text-decoration: underline;
    }
  }
`;
