import styled from 'styled-components';
import { mobileMenuBreakpoint } from '../breakpoints';

export const Container = styled.div`
  width: 1.2rem;
  height: 1.125rem;
  display: none;
  cursor: pointer;

  @media (max-width: ${mobileMenuBreakpoint}) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }

    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

export const Line = styled.div`
  width: 1.2rem;
  height: 0.125rem;
  background-color: ${props => props.theme.colors.light};
  transform-origin: 1px;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
`;
