export const theme = {
  colors: {
    primary: '#69a3ff',
    primaryDark: '#418bff',
    primaryDarker: '#0246b1',
    primaryDarkest: '#012866',
    secondary: '#222222',
    secondaryLighter: '#333333',
    dark: '#000000',
    light: '#ffffff',
    error: '#ff0000',
  },
  fonts: {
    default: 'Open Sans, sans-serif',
  },
};

export default theme;
