import React from 'react';
import { Container } from './styled';
import logo from './images/logo-medium.png';

const Header: React.FC = () => {
  return (
    <Container>
      <img src={logo} width="134" height="116" alt="logo" />
    </Container>
  );
};

export default Header;
