/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, ReactElement, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ReactPlayer from 'react-player';
import {
  ProjectContainer as Container,
  Project as ProjectBox,
  Content,
  Image,
  Text,
  Tabs,
  Tab,
  TabContent,
  ProjectInner,
} from '../styled';
import { Project, RootState } from '../../../interfaces';
import {
  getGameProjectsOwnProduction,
  getGameProjectsForOthers,
} from './selectors';

const connector = connect((state: RootState) => ({
  gameProjectsOwnProduction: getGameProjectsOwnProduction(state),
  gameProjectsForOthers: getGameProjectsForOthers(state),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const Games: FC<PropsFromRedux> = (props: PropsFromRedux): ReactElement => {
  const { gameProjectsOwnProduction, gameProjectsForOthers } = props;
  const [active, setActive] = useState(0);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    const { id } = target.dataset;
    if (id !== undefined) {
      const index = parseInt(id, 2);
      if (index !== active) {
        setActive(index);
      }
    }
  };
  return (
    <Container>
      <h1>Games</h1>
      <p className="description">
        Here you can find some games I've created or worked on. All pixel art
        and animations for my own games, I create using{' '}
        <a href="https://www.aseprite.org/" target="_blank">
          Aseprite
        </a>
        , an animated sprite editor &amp; pixel art tool.
      </p>

      <Tabs>
        <Tab onClick={handleClick} active={active === 0} data-id={0}>
          Own production
        </Tab>

        <Tab onClick={handleClick} active={active === 1} data-id={1}>
          For other companies
        </Tab>
      </Tabs>
      <TabContent active={active === 0}>
        {gameProjectsOwnProduction &&
          gameProjectsOwnProduction.map((project: Project) => (
            <ProjectBox key={project.name}>
              <h2>{project.name}</h2>
              <ProjectInner>
                <Content>
                  <a href={project.url} target="_blank" rel="noreferrer">
                    <Image
                      src={`${process.env.PUBLIC_URL}/images/projects/${project.image}`}
                      width={project.imageWidth}
                      height={project.imageHeight}
                      alt={project.name}
                    />
                  </a>
                  <Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: project.description,
                      }}
                    />
                    {project.technology && (
                      <p>Used technology: {project.technology}</p>
                    )}
                    {project.company && <p>Company: {project.company}</p>}
                    {project.url && (
                      <p>
                        <a
                          className="project-link"
                          href={project.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Open project in new tab
                        </a>
                      </p>
                    )}
                  </Text>
                </Content>
              </ProjectInner>
            </ProjectBox>
          ))}
      </TabContent>
      <TabContent active={active === 1}>
        {gameProjectsForOthers &&
          gameProjectsForOthers.map((project: Project) => (
            <ProjectBox key={project.name}>
              <h2>{project.name}</h2>
              <ProjectInner>
                <Content>
                  <a href={project.url} target="_blank" rel="noreferrer">
                    {project.image !== undefined && (
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/projects/${project.image}`}
                        width={project.imageWidth}
                        height={project.imageHeight}
                        alt={project.name}
                      />
                    )}
                    {project.movie !== undefined && (
                      <ReactPlayer
                        width={320}
                        height={240}
                        controls={false}
                        muted
                        playing={active === 1}
                        loop
                        url={project.movie}
                      />
                    )}
                  </a>
                  <Text>
                    <div
                      dangerouslySetInnerHTML={{ __html: project.description }}
                    />
                    {project.technology && (
                      <p>Used technology: {project.technology}</p>
                    )}
                    {project.company && <p>Company: {project.company}</p>}
                    {project.url && (
                      <p>
                        <a
                          className="project-link"
                          href={project.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Open project in new tab
                        </a>
                      </p>
                    )}
                  </Text>
                </Content>
              </ProjectInner>
            </ProjectBox>
          ))}
      </TabContent>
    </Container>
  );
};

export default connector(Games);
