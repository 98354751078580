import React from 'react';
import PropTypes from 'prop-types';
import { Container, NavigationWrapper, Item, Link } from './styled';

/* eslint-disable */
const Links = ({ navigation, open, onClick }) => {
  return (
    <Container open={open}>
      <NavigationWrapper>
        {navigation.map((data, index) => (
          <Item key={`link-${data.id}`}>
            <Link
              to={data.to}
              onPointerDown={() => {
                setTimeout(() => {
                  onClick();
                }, 300);
              }}>
              {data.label}
            </Link>
          </Item>
        ))}
      </NavigationWrapper>
    </Container>
  );
};

Links.propTypes = {
  navigation: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func.isRequired,
};

export default Links;
