import styled from 'styled-components';

const usePaddingBreakpoint = '1200px';

export const Wrapper = styled.footer`
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${(props) => props.theme.colors.light};
  padding: 0.5rem 1rem;
  @media (min-width: ${usePaddingBreakpoint}) {
    padding: 0.5rem 6.5rem;
  }
  background-color: ${({ theme }) => theme.colors.secondary};
  a {
    color: ${(props) => props.theme.colors.light};
  }
  a:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Container = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  text-align: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  li::before {
    content: '■ ';
    color: ${({ theme }) => theme.colors.primary};
  }

  li {
    margin: 0.5rem 2rem;
    white-space: nowrap;
  }
`;
