import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Error404: React.FC = () => {
  const location = useLocation();

  return (
    <div>
      <h3>
        Oops, we could't find page <code>{location.pathname}.</code>
      </h3>
      <p>
        <Link to="/">Go back to the homepage</Link>.
      </p>
    </div>
  );
};

export default Error404;
