import React from 'react';
import { Wrapper, Container } from './styled';

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <li>KvK 65664930</li>
        <li>
          <a
            href="https://www.linkedin.com/in/jreurings"
            target="_blank"
            rel="noreferrer"
          >
            Linkedin
          </a>
        </li>
        <li>
          <a
            href="mailto:info@spriteshaper.com"
            target="_blank"
            rel="noreferrer"
          >
            info@spriteshaper.com
          </a>
        </li>
        <li>+31 (0)6 14347754</li>
      </Container>
    </Wrapper>
  );
};

export default Footer;
