import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ThemeProvider } from 'styled-components';
import { PageWrapper, Container, Global } from './styled';
import { Footer, ScrollToTop, Navigation } from './components';
import Default from './theme/Default';
import {
  Error404,
  Home,
  Websites,
  Games,
  Electronics,
} from './components/pages';
import { GetProjectsAction } from './interfaces';
import { GET_PROJECTS } from './actions';
import projects from './data/projects.json';
import Experiments from './components/pages/Experiments';

const initialState = {};

type ActionsTypes = GetProjectsAction;

function projectReducer(state = initialState, action: ActionsTypes) {
  switch (action.type) {
    case GET_PROJECTS:
      return { ...state, projects: action.payload };
    default:
      return state;
  }
}

const store = createStore(projectReducer, composeWithDevTools());

store.dispatch({
  type: GET_PROJECTS,
  payload: projects,
});

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Global />
      <ThemeProvider theme={Default}>
        <PageWrapper id="page-wrapper">
          <Router>
            <ScrollToTop />
            <Navigation
              navigation={[
                {
                  id: 1,
                  label: 'Websites',
                  to: '/websites',
                },
                {
                  id: 2,
                  label: 'Games',
                  to: '/games',
                },
                {
                  id: 3,
                  label: 'Electronics',
                  to: '/electronics',
                },
                {
                  id: 4,
                  label: 'Experiments',
                  to: '/experiments',
                },
              ]}
            />

            <Switch>
              <Route exact path="/">
                <Container isHome>
                  <Home />
                </Container>
              </Route>
              <Route exact path="/websites">
                <Container>
                  <Websites />
                </Container>
              </Route>
              <Route exact path="/games">
                <Container>
                  <Games />
                </Container>
              </Route>
              <Route exact path="/electronics">
                <Container>
                  <Electronics />
                </Container>
              </Route>
              <Route exact path="/experiments">
                <Container>
                  <Experiments />
                </Container>
              </Route>
              <Route path="*">
                <Container>
                  <Error404 />
                </Container>
              </Route>
            </Switch>
            <Footer />
          </Router>
        </PageWrapper>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
