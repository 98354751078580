import React from 'react';
import PropTypes from 'prop-types';
import Burger from '../Burger';
import Icon from './assets/logo-small-text.png';
import { Nav, BrandContainer, BrandIcon, Link } from './styled';

const Bar = ({ navigation }) => (
  <Nav>
    <BrandContainer>
      <Link to="/">
        <BrandIcon width="164" height="37" src={Icon} alt="logo" />
      </Link>
    </BrandContainer>
    <Burger navigation={navigation} />
  </Nav>
);

Bar.propTypes = {
  navigation: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Bar;
