import styled from 'styled-components';

const usePaddingBreakpoint = '1200px';

const Container = styled.div<{ isHome?: boolean }>`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.8rem;
  flex: 1 0 auto;

  padding: 0 1rem;
  @media (min-width: ${usePaddingBreakpoint}) {
    padding: 0 6.5rem;
  }

  display: flex;
  align-items: center;
  justify-content: ${({ isHome }) => (isHome ? 'center' : 'flex-start')};
  flex-direction: column;

  a {
    color: ${({ theme }) => theme.colors.primaryDarker};
  }

  h1,
  h2 {
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }
  h1 {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
  h2 {
    font-size: 1rem;
  }
`;

export default Container;
