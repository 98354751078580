import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  background: #ddd;
  border: 1px solid #777;
  border-radius: 0.5rem;

  h1 {
    font-size: 1.1rem;
    color: #0246b1;
  }
  p {
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  margin-top: 0.5rem;
  text-align: center;

  p {
    color: #000;
  }
`;
