import React, { FC, ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  ProjectContainer as Container,
  Project as ProjectBox,
  Content,
  Image,
  Text,
  ProjectInner,
} from '../styled';
import { Project, RootState } from '../../../interfaces';
import { getElectronicsProjects } from './selectors';

const connector = connect((state: RootState) => ({
  projects: getElectronicsProjects(state),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const Electronics: FC<PropsFromRedux> = (
  props: PropsFromRedux,
): ReactElement => {
  const { projects } = props;
  return (
    <Container>
      <h1>Electronics</h1>
      <p className="description">
        Here you can find some electronics projects I've worked on.
      </p>
      {projects &&
        projects.map((project: Project) => (
          <ProjectBox key={project.name}>
            <h2>{project.name}</h2>
            <ProjectInner>
              <Content>
                <a href={project.url} target="_blank" rel="noreferrer">
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/projects/${project.image}`}
                    width={project.imageWidth}
                    height={project.imageHeight}
                    alt={project.name}
                  />
                </a>
                <Text>
                  <div
                    dangerouslySetInnerHTML={{ __html: project.description }}
                  />
                  {project.technology && (
                    <p>Used technology: {project.technology}</p>
                  )}
                  {project.company && <p>Company: {project.company}</p>}
                  {project.url && (
                    <p>
                      <a
                        className="project-link"
                        href={project.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Open project in new tab
                      </a>
                    </p>
                  )}
                </Text>
              </Content>
            </ProjectInner>
          </ProjectBox>
        ))}
    </Container>
  );
};

export default connector(Electronics);
