import styled from 'styled-components';

export const ProjectContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  p {
    margin-bottom: 0;
    &.description {
      margin-bottom: 1rem;
    }
  }
  max-width: 72rem;
  margin: 1.5rem 0 3rem;
`;

export const Content = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    flex-flow: column nowrap;
  }
  a {
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.primaryDarkest};
    &:hover {
      color: ${({ theme }) => theme.colors.dark};
    }
  }
  a.project-link {
    display: inline-flex;
  }
`;

export const Text = styled.div`
  padding: 0 1.2rem;
  @media (max-width: 768px) {
    padding: 1.2rem 0 0 0;
  }
  margin: 0;
  display: flex;
  flex-direction: column;
  p:first-child {
    margin-top: 0;
  }
`;

export const Project = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: #ddd;
  border: 1px solid #777;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 1rem;
  }
  margin-top: 0.5rem;
  h2 {
    background: #dcdcdc;
    margin: 0;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #bbb;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
`;

export const ProjectInner = styled.div`
  background: #e9e9e9;
  padding: 1rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
`;

export const Image = styled.img`
  border: 1px solid #bbb;
  border-radius: 0.5rem;

  @media (min-width: 768px) {
    /* Safari fix */
    /* https://stackoverflow.com/questions/57516373/image-stretching-in-flexbox-in-safari */
    width: intrinsic;
    height: intrinsic;
  }
`;

export const Tabs = styled.div`
  overflow: hidden;
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const Tab = styled.button<{ active: boolean }>`
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 0.5rem 1rem;
  margin-left: 0.6em;
  font-size: 1em;
  border-radius: 0.5rem;
  border: 1px solid #777;
  background-color: ${(props) =>
    props.active ? 'rgba(255, 255, 255, 0.6)' : 'rgba(255, 255, 255, 0.1)'};
  transition: background-color 0.3s ease-in-out;

  :hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

export const TabContent = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.active ? '' : 'display:none')}
`;
