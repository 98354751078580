import React from 'react';
import { Container, Text } from './styled';
import { Logo } from './components';

const Home: React.FC = (): React.ReactElement => (
  <Container>
    <Logo />
    <Text>
      {/* <h1>Jeroen Reurings</h1> */}
      <p>Games &amp; Websites</p>
    </Text>
  </Container>
);

export default Home;
