import { Project, RootState } from '../../../interfaces';

export const getGameProjectsOwnProduction = (state: RootState): Project[] => {
  return state.projects
    .filter((project: Project) => {
      return project.type === 'game' && project.company === 'Own production';
    })
    .sort((a, b) => a.order - b.order);
};

export const getGameProjectsForOthers = (state: RootState): Project[] => {
  return state.projects
    .filter((project: Project) => {
      return project.type === 'game' && project.company !== 'Own production';
    })
    .sort((a, b) => a.order - b.order);
};
