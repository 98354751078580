import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Links from '../Links';
import { Container, Line } from './styled';

const Burger = ({ navigation, languageSwitcher }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = open ? 'hidden' : 'initial';
  }, [open]);

  return (
    <>
      <Container open={open} onClick={() => setOpen(!open)}>
        <Line />
        <Line />
        <Line />
      </Container>
      <Links
        open={open}
        navigation={navigation}
        onClick={() => setOpen(false)}
      />
    </>
  );
};

Burger.propTypes = {
  navigation: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Burger;
