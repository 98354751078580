import React from 'react';
import PropTypes from 'prop-types';
import Bar from './Bar';

export const Navigation = ({ navigation }) =>
  <Bar navigation={navigation} />;

Navigation.propTypes = {
  navigation: PropTypes.arrayOf(PropTypes.object).isRequired,
};
