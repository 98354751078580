import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { usePaddingBreakpoint } from '../breakpoints';

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${props => props.theme.colors.light};
  padding: 0.5rem 1rem;
  min-height: 53px;
  @media (min-width: ${usePaddingBreakpoint}) {
    padding: 0.5rem 6.5rem;
  }
  background-color: ${({ theme }) => theme.colors.secondary};
  z-index: 100;
  position: sticky;
  top: 0;
`;

export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BrandIcon = styled.img`
  margin-right: 1rem;
`;

export const Link = styled(RouterLink)`
  display: contents;
`;
