import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

  body {
    font-size: 10px;
    overflow-x: hidden;
  }

  body,
  html {
    margin: 0;
    padding: 0;
    height: 100%;
    background: rgb(221,221,221);
    background: linear-gradient(0deg, rgba(221,221,221,1) 0%, rgba(204,204,204,1) 25%, rgba(203,203,203,1) 50%, rgba(204,204,204,1) 75%, rgba(221,221,221,1) 100%);
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
      box-sizing: inherit;
  }
`;

export default Global;
